var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Spin",
    { staticClass: "empty-card", attrs: { show: true } },
    [_vm._t("default", [_c("span", [_vm._v(_vm._s(_vm.$t("no_data")))])])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
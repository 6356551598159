import i18n from '@/i18n'

const app_key = process.env.VUE_APP_STRIPE
const square_appid = process.env.VUE_APP_SQUARE_APPID
const square_locationid = process.env.VUE_APP_SQUARE_LOCATIONID
const square_url = process.env.VUE_APP_SQUARE_URL
const server_name1 = process.env.VUE_APP_SERVER_NAME1
const server_name2 = process.env.VUE_APP_SERVER_NAME2
const locale = process.env.VUE_APP_I18N_LOCALE
const email_support = process.env.VUE_APP_EMAIL_SUPPORT
const cloudclustersURL = process.env.VUE_APP_WEB_SITE
const isDev = process.env.NODE_ENV === 'development'
const portal_url = process.env.VUE_APP_PORTAL_URL
const chat_link = process.env.VUE_APP_CHAT_LINK
const limitSize = {
  errMsg: 'File size exceeds 20M',
  size: 1024 * 1024 * 20
}

const shellURL = process.env.VUE_APP_SHELL
// process.env.NODE_ENV !== 'development'
const domainExtension = ['.com', '.net', '.org', '.biz', '.us', '.name']
const perUlrMap = {
  GPU: 'gpu-servers',
  APP: 'applications',
  VM: 'myvps',
  SSL: 'ssl',
  Domain: 'domain',
  Custom: 'k8s-servers',
  'Bare Metal': 'dedicated-servers'
}
// "Pending":"commissionStatus.pending",
//     "Available":"commissionStatus.available",
//     "Withdrawing" :"commissionStatus.withdrawing",
//     "Withdrawn":"commissionStatus.withdrawn",
//     "Invalid ":"commissionStatus.invalid",
const colorMap = {
  Active: '#10c610',
  Available: '#10c610',
  Paid: '#10c610',
  'Partially Paid': '#5574E5',
  Approved: '#10c610',
  'Fully Refunded': '#673ab7',
  'Partially Refunded': '#9c27b0',
  Pending: '#FFB400',
  Adding: '#FFB400',
  Deleting: '#FFB400',
  'Charged Back': '#FA4767',
  Withdrawn: '#5574E5',
  Suspended: '#495ab9',
  Cancelled: '#d9d9d9',
  AdditionFailed: '#F56c62',
  DeletionFailed: '#FF5252',
  AdditionTimeout: '#F56c6c',
  DeletionTimeout: '#F56c6c',
  Unpaid: '#F56c62',
  'Deploy Failed': '#FF5252',
  Rejected: '#F56c6c',
  Invalid: '#F56c6c',
  Unconfirmed: '#F57D0E',
  Withdrawing: '#F57D0E',
  'Pending Removal': '#FFC107',
  'Pending Activation': '#FFC107',
  'Active-bg': '#E1FFE1',
  'Suspended-bg': '#e0e5ff',
  'Cancelled-bg': '#cccccc',
  'Deploy Failed-bg': '#FFE0E0',
  'Pending-bg': '#FFF0E0',
  running: '#028e01',
  'shut off': '#ff5a01',
  paused: '#8e0000',
  error: '#D65C5C'
}

const transDiscount = function (value) {
  let discount = parseInt(value)
  if (locale === 'zh_CN') {
    return (100 - discount) / 10
  } else {
    return discount
  }
}
const categoryMap = [
  { name: 'C11', text: 'A natural person who is a United States citizen.' },
  {
    name: 'C12',
    text: 'A natural person who is a permanent resident of the United States of America, or any of its possessions or territories.'
  },
  { name: 'C21', text: 'A US-based organization or company' },
  { name: 'C31', text: 'A foreign entity or organization' },
  { name: 'C32', text: 'Entity has an office or other facility in the United States.' }
]
const purposeMap = [
  {
    name: 'P1',
    text: 'Business use for profit.'
  },
  {
    name: 'P2',
    text: 'Non-profit business, club, association, religious organization, etc.'
  },
  {
    name: 'P3',
    text: 'Personal use.'
  },
  {
    name: 'P4',
    text: 'Education purposes.'
  },
  {
    name: 'P5',
    text: 'Government purposes.'
  }
]
const domain = {
  SSO: process.env.VUE_APP_SSO
}
const status = {
  Pending: {},
  Active: {},
  Suspended: {},
  Cancelled: {}
}
const timezone = [
  {
    description: '(UTC-12:00) International Date Line West',
    key: 'Etc/GMT+12'
  },
  {
    description: '(UTC-11:00) Coordinated Universal Time-11',
    key: 'Etc/GMT+11'
  },
  {
    description: '(UTC-10:00) Aleutian Islands',
    key: 'US/Aleutian'
  },
  {
    description: '(UTC-10:00) Hawaii',
    key: 'US/Hawaii'
  },
  {
    description: '(UTC-09:30) Marquesas Islands',
    key: 'Pacific/Marquesas'
  },
  {
    description: '(UTC-09:00) Coordinated Universal Time-09',
    key: 'Etc/GMT+9'
  },
  {
    description: '(UTC-09:00) Alaska',
    key: 'US/Alaska'
  },
  {
    description: '(UTC-08:00) Baja California',
    key: 'Mexico/BajaNorte'
  },
  {
    description: '(UTC-08:00) Coordinated Universal Time-08',
    key: 'Etc/GMT+8'
  },
  {
    description: '(UTC-08:00) Pacific Time (US & Canada)',
    key: 'US/Pacific'
  },
  {
    description: '(UTC-07:00) Yukon',
    key: 'Canada/Yukon'
  },
  {
    description: '(UTC-07:00) Mountain Time (US & Canada)',
    key: 'US/Mountain'
  },
  {
    description: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    key: 'America/Mazatlan'
  },
  {
    description: '(UTC-07:00) Arizona',
    key: 'US/Arizona'
  },
  {
    description: '(UTC-06:00) Easter Island',
    key: 'Chile/EasterIsland'
  },
  {
    description: '(UTC-06:00) Saskatchewan',
    key: 'Canada/Saskatchewan'
  },
  {
    description: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    key: 'America/Guatemala'
  },
  {
    description: '(UTC-06:00) Central Time (US & Canada)',
    key: 'US/Central'
  },
  {
    description: '(UTC-06:00) Central America',
    key: 'America/Belize'
  },
  {
    description: '(UTC-05:00) Eastern Time (US & Canada)',
    key: 'US/Eastern'
  },
  {
    description: '(UTC-05:00) Indiana (East)',
    key: 'America/Indiana/Indianapolis'
  },
  {
    description: '(UTC-05:00) Chetumal',
    key: 'America/Cancun'
  },
  {
    description: '(UTC-05:00) Turks and Caicos',
    key: 'America/Grand_Turk'
  },
  {
    description: '(UTC-05:00) Bogota, Lima, Quito, Rio Branco',
    key: 'America/Bogota'
  },
  {
    description: '(UTC-05:00) Havana',
    key: 'America/Havana'
  },
  {
    description: '(UTC-05:00) Haiti',
    key: 'America/Port-au-Prince'
  },
  {
    description: '(UTC-04:00) Atlantic Time (Canada)',
    key: 'Canada/Atlantic'
  },
  {
    description: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    key: 'America/La_Paz'
  },
  {
    description: '(UTC-04:00) Caracas',
    key: 'America/Caracas'
  },
  {
    description: '(UTC-04:00) Cuiaba',
    key: 'America/Cuiaba'
  },
  {
    description: '(UTC-04:00) Santiago',
    key: 'America/Santiago'
  },
  {
    description: '(UTC-04:00) Asuncion',
    key: 'America/Asuncion'
  },
  {
    description: '(UTC-03:30) Newfoundland',
    key: 'Canada/Newfoundland'
  },
  {
    description: '(UTC-03:00) Salvador',
    key: 'America/El_Salvador'
  },
  {
    description: '(UTC-03:00) Brasilia',
    key: 'America/Sao_Paulo'
  },
  {
    description: '(UTC-03:00) Montevideo',
    key: 'America/Montevideo'
  },
  {
    description: '(UTC-03:00) Punta Arenas',
    key: 'America/Punta_Arenas'
  },
  {
    description: '(UTC-03:00) Araguaina',
    key: 'America/Araguaina'
  },
  {
    description: '(UTC-03:00) Saint Pierre and Miquelon',
    key: 'America/Miquelon'
  },
  {
    description: '(UTC-03:00) Cayenne, Fortaleza',
    key: 'America/Cayenne'
  },
  {
    description: '(UTC-03:00) Greenland',
    key: 'America/Godthab'
  },
  {
    description: '(UTC-03:00) City of Buenos Aires',
    key: 'America/Argentina/Buenos_Aires'
  },
  {
    description: '(UTC-02:00) Coordinated Universal Time-02',
    key: 'Etc/GMT+2'
  },
  {
    description: '(UTC-01:00) Azores',
    key: 'Atlantic/Azores'
  },
  {
    description: '(UTC-01:00) Cabo Verde Is.',
    key: 'Atlantic/Cape_Verde'
  },
  {
    description: '(UTC+00:00) Dublin, Edinburgh, Lisbon, London',
    key: 'Europe/London'
  },
  {
    description: '(UTC+00:00) Monrovia, Reykjavik',
    key: 'Africa/Monrovia'
  },
  {
    description: '(UTC+00:00) Sao Tome',
    key: 'Africa/Sao_Tome'
  },
  {
    description: '(UTC) Coordinated Universal T',
    key: 'Etc/UTC'
  },
  {
    description: '(UTC+01:00) Casablanca',
    key: 'Africa/Casablanca'
  },
  {
    description: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    key: 'Europe/Berlin'
  },
  {
    description: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    key: 'Europe/Sarajevo'
  },
  {
    description: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    key: 'Europe/Belgrade'
  },
  {
    description: '(UTC+01:00) West Central Africa',
    key: 'Africa/Douala'
  },
  {
    description: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    key: 'Europe/Paris'
  },
  {
    description: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    key: 'Europe/Helsinki'
  },
  {
    description: '(UTC+02:00) Kaliningrad',
    key: 'Europe/Kaliningrad'
  },
  {
    description: '(UTC+02:00) Beirut',
    key: 'Asia/Beirut'
  },
  {
    description: '(UTC+02:00) Amman',
    key: 'Asia/Amman'
  },
  {
    description: '(UTC+02:00) Gaza, Hebron',
    key: 'Asia/Gaza'
  },
  {
    description: '(UTC+02:00) Khartoum',
    key: 'Africa/Khartoum'
  },
  {
    description: '(UTC+02:00) Damascus',
    key: 'Asia/Damascus'
  },
  {
    description: '(UTC+02:00) Tripoli',
    key: 'Africa/Tripoli'
  },
  {
    description: '(UTC+02:00) Jerusalem',
    key: 'Asia/Jerusalem'
  },
  {
    description: '(UTC+02:00) Juba',
    key: 'Africa/Juba'
  },
  {
    description: '(UTC+02:00) Chisinau',
    key: 'Europe/Chisinau'
  },
  {
    description: '(UTC+02:00) Cairo',
    key: 'Africa/Cairo'
  },
  {
    description: '(UTC+02:00) Windhoek',
    key: 'Africa/Windhoek'
  },
  {
    description: '(UTC+02:00) Athens, Bucharest',
    key: 'Europe/Athens'
  },
  {
    description: '(UTC+02:00) Harare, Pretoria',
    key: 'Africa/Harare'
  },
  {
    description: '(UTC+03:00) Nairobi',
    key: 'Africa/Nairobi'
  },
  {
    description: '(UTC+03:00) Volgograd',
    key: 'Europe/Volgograd'
  },
  {
    description: '(UTC+03:00) Kuwait, Riyadh',
    key: 'Asia/Kuwait'
  },
  {
    description: '(UTC+03:00) Moscow, St. Petersburg',
    key: 'Europe/Moscow'
  },
  {
    description: '(UTC+03:00) Baghdad',
    key: 'Asia/Baghdad'
  },
  {
    description: '(UTC+03:00) Istanbul',
    key: 'Asia/Istanbul'
  },
  {
    description: '(UTC+03:00) Minsk',
    key: 'Europe/Minsk'
  },
  {
    description: '(UTC+03:30) Tehran',
    key: 'Asia/Tehran'
  },
  {
    description: '(UTC+04:00) Astrakhan, Ulyanovsk',
    key: 'Europe/Astrakhan'
  },
  {
    description: '(UTC+04:00) Tbilisi',
    key: 'Asia/Tbilisi'
  },
  {
    description: '(UTC+04:00) Yerevan',
    key: 'Asia/Yerevan'
  },
  {
    description: '(UTC+04:00) Baku',
    key: 'Asia/Baku'
  },
  {
    description: '(UTC+04:00) Saratov',
    key: 'Europe/Saratov'
  },
  {
    description: '(UTC+04:00) Port Louis',
    key: 'Indian/Mauritius'
  },
  {
    description: '(UTC+04:00) Izhevsk, Samara',
    key: 'Europe/Samara'
  },
  {
    description: '(UTC+04:00) Abu Dhabi, Muscat',
    key: 'Asia/Muscat'
  },
  {
    description: '(UTC+04:30) Kabul',
    key: 'Asia/Kabul'
  },
  {
    description: '(UTC+05:00) Ekaterinburg',
    key: 'Asia/Yekaterinburg'
  },
  {
    description: '(UTC+05:00) Ashgabat, Tashkent',
    key: 'Asia/Ashgabat'
  },
  {
    description: '(UTC+05:00) Islamabad, Karachi',
    key: 'Asia/Karachi'
  },
  {
    description: '(UTC+05:00) Qyzylorda',
    key: 'Asia/Qyzylorda'
  },
  {
    description: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    key: 'Asia/Kolkata'
  },
  {
    description: '(UTC+05:30) Sri Jayawardenepura',
    key: 'Asia/Colombo'
  },
  {
    description: '(UTC+05:45) Kathmandu',
    key: 'Asia/Kathmandu'
  },
  {
    description: '(UTC+06:00) Dhaka',
    key: 'Asia/Dhaka'
  },
  {
    description: '(UTC+06:00) Astana',
    key: 'Asia/Almaty'
  },
  {
    description: '(UTC+06:00) Omsk',
    key: 'Asia/Omsk'
  },
  {
    description: '(UTC+06:30) Yangon (Rangoon)',
    key: 'Asia/Yangon'
  },
  {
    description: '(UTC+07:00) Barnaul, Gorno-Altaysk',
    key: 'Asia/Barnaul'
  },
  {
    description: '(UTC+07:00) Hovd',
    key: 'Asia/Hovd'
  },
  {
    description: '(UTC+07:00) Krasnoyarsk',
    key: 'Asia/Krasnoyarsk'
  },
  {
    description: '(UTC+07:00) Novosibirsk',
    key: 'Asia/Novosibirsk'
  },
  {
    description: '(UTC+07:00) Tomsk',
    key: 'Asia/Tomsk'
  },
  {
    description: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    key: 'Asia/Bangkok'
  },
  {
    description: '(UTC+08:00) Irkutsk',
    key: 'Asia/Irkutsk'
  },
  {
    description: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    key: 'Asia/Chongqing'
  },
  {
    description: '(UTC+08:00) Ulaanbaatar',
    key: 'Asia/Ulaanbaatar'
  },
  {
    description: '(UTC+08:00) Taipei',
    key: 'Asia/Taipei'
  },
  {
    description: '(UTC+08:00) Perth',
    key: 'Australia/Perth'
  },
  {
    description: '(UTC+08:00) Kuala Lumpur, Singapore',
    key: 'Asia/Singapore'
  },
  {
    description: '(UTC+08:45) Eucla',
    key: 'Australia/Eucla'
  },
  {
    description: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    key: 'Asia/Tokyo'
  },
  {
    description: '(UTC+09:00) Pyongyang',
    key: 'Asia/Pyongyang'
  },
  {
    description: '(UTC+09:00) Seoul',
    key: 'Asia/Seoul'
  },
  {
    description: '(UTC+09:00) Yakutsk',
    key: 'Asia/Yakutsk'
  },
  {
    description: '(UTC+09:00) Chita',
    key: 'Asia/Chita'
  },
  {
    description: '(UTC+09:30) Darwin',
    key: 'Australia/Darwin'
  },
  {
    description: '(UTC+09:30) Adelaide',
    key: 'Australia/Adelaide'
  },
  {
    description: '(UTC+10:00) Brisbane',
    key: 'Australia/Brisbane'
  },
  {
    description: '(UTC+10:00) Vladivostok',
    key: 'Asia/Vladivostok'
  },
  {
    description: '(UTC+10:00) Canberra, Melbourne, Sydney',
    key: 'Australia/Sydney'
  },
  {
    description: '(UTC+10:00) Guam, Port Moresby',
    key: 'Pacific/Guam'
  },
  {
    description: '(UTC+10:00) Hobart',
    key: 'Australia/Hobart'
  },
  {
    description: '(UTC+10:30) Lord Howe Island',
    key: 'Australia/Lord_Howe'
  },
  {
    description: '(UTC+11:00) Solomon Is., New Caledonia',
    key: 'Pacific/Guadalcanal'
  },
  {
    description: '(UTC+11:00) Chokurdakh, Magadan',
    key: 'Asia/Magadan'
  },
  {
    description: '(UTC+11:00) Norfolk Island',
    key: 'Pacific/Norfolk'
  },
  {
    description: '(UTC+11:00) Sakhalin',
    key: 'Asia/Sakhalin'
  },
  {
    description: '(UTC+11:00) Bougainville Island',
    key: 'Pacific/Bougainville'
  },
  {
    description: '(UTC+12:00) Coordinated Universal Time+12',
    key: 'Etc/GMT-12'
  },
  {
    description: '(UTC+12:00) Auckland, Wellington',
    key: 'Pacific/Auckland'
  },
  {
    description: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
    key: 'Asia/Anadyr'
  },
  {
    description: '(UTC+12:00) Fiji',
    key: 'Pacific/Fiji'
  },
  {
    description: '(UTC+12:45) Chatham Islands',
    key: 'Pacific/Chatham'
  },
  {
    description: '(UTC+13:00) Samoa',
    key: 'Pacific/Apia'
  },
  {
    description: '(UTC+13:00) Coordinated Universal Time+13',
    key: 'Etc/GMT-13'
  },
  {
    description: "(UTC+13:00) Nuku'alofa",
    key: 'Pacific/Tongatapu'
  },
  {
    description: '(UTC+14:00) Kiritimati Island',
    key: 'Pacific/Kiritimati'
  }
]
const packageStatusI18n = {
  Cancelled: 'status.cancelled',
  'Deploy Failed': 'status.deploy_failed',
  process: 'status.process',
  Active: 'status.active',
  Suspended: 'status.suspended',
  Pending: 'status.pending',
  Unconfirmed: 'status.unconfirmed'
}

const packageStatusFilter = [
  { text: i18n.t('status.active'), value: 'Active' },
  { text: i18n.t('status.suspended'), value: 'Suspended' },
  { text: i18n.t('status.pending'), value: 'Pending' },
  { text: i18n.t('status.unconfirmed'), value: 'Unconfirmed' },
  { text: i18n.t('status.cancelled'), value: 'Cancelled' }
]

// package status default selected
const packageStatusDefaultSelected = ['Active', 'Suspended', 'Pending', 'Unconfirmed']

const addonStatusI18n = {
  Cancelled: 'status.addon_cancelled',
  Active: 'status.active',
  Pending: 'status.pending',
  'Pending Activation': 'status.pending_activation',
  'Pending Removal': 'status.pending_removal'
}

const departmentsI18n = {
  'Billing Department': 'departments.billing',
  'Sales Department': 'departments.sales',
  'Support Department': 'departments.support',
  'Operation Department': 'departments.operation'
}

const cancelReasonI18n = {
  'Overdue On Payment': 'cancelReason.overdue',
  'Cancellation Request': 'cancelReason.cancel_req',
  'Failed Identity Verification': 'cancelReason.fail_verify',
  'Cancelled by Agent': 'cancelReason.cancel_agent',
  'Suspended by Agent': 'cancelReason.suspend_agent',
  'Account Closed': 'cancelReason.closed',
  'Resource Abuse': 'cancelReason.resource_abuse',
  'Resource Overflow': 'cancelReason.resource_overflow'
}
const runningStatusI18n = {
  running: 'runningStatus.running',
  'shut off': 'runningStatus.shut_off',
  paused: 'runningStatus.paused',
  error: 'runningStatus.error'
}

const ipStatusI18n = {
  Pending: 'ipStatus.pending',
  Adding: 'ipStatus.adding',
  Deleting: 'ipStatus.deleting',
  Active: 'ipStatus.active',
  AdditionFailed: 'ipStatus.addition_failed',
  DeletionFailed: 'ipStatus.deletion_failed',
  AdditionTimeout: 'ipStatus.addition_timeout',
  DeletionTimeout: 'ipStatus.deletion_timeout'
}
const paidStatusI18n = {
  Unpaid: 'paidStatus.unpaid',
  Paid: 'paidStatus.paid',
  'Partially Paid': 'paidStatus.partially_paid',
  Cancelled: 'paidStatus.cancelled',
  'Fully Refunded': 'paidStatus.fully_refunded',
  'Partially Refunded': 'paidStatus.partially_refunded',
  Draft: 'paidStatus.draft',
  'Charged Back': 'paidStatus.charged_back'
}

const paymentMethodI18n = {
  'Credit Card': 'payment_method.credit_card',
  'Credit Applied': 'payment_method.credit',
  PayPal: 'payment_method.paypal',
  AliPay: 'payment_method.ali_pay',
  'WeChat Pay': 'payment_method.wechat_pay'
}
const approvalMethod = [
  {
    name: 'DNS',
    value: 'DNS'
  },
  {
    name: 'HTTP',
    value: 'FILE'
  },
  {
    name: 'EMAIL',
    value: 'EMAIL'
  }
]
const productTypeI18n = {
  VM: 'productType.vm',
  APP: 'productType.app',
  'Bare Metal': 'productType.bare_metal'
}

const commissionStatusI18n = {
  Pending: 'commissionStatus.pending',
  Approved: 'commissionStatus.approved',
  Rejected: 'commissionStatus.rejected',
  Cancelled: 'commissionStatus.cancelled'
}
const affOrderStatusI18n = {
  Pending: 'affStatus.pending',
  Available: 'affStatus.available',
  Withdrawing: 'affStatus.withdrawing',
  Withdrawn: 'affStatus.withdrawn',
  Invalid: 'affStatus.invalid'
}

const packageCategory = ['Database', 'CMS']
const packageStatus = ['Pending', 'Active', 'Suspended', 'Cancelled']
const deployStatus = [
  'Deploying',
  'Success',
  'Failure',
  'Timeout',
  'Deleting',
  'Deleted',
  'DeleteFailed'
]

const cancellationTypes = ['Immediate', 'End of Billing Cycle']

const allCountries = [
  ['United States', 'us', '1', 0],
  ['United Kingdom', 'gb', '44', 0],
  ['Afghanistan (‫افغانستان‬‎)', 'af', '93'],
  ['Albania (Shqipëri)', 'al', '355'],
  ['Algeria (‫الجزائر‬‎)', 'dz', '213'],
  ['American Samoa', 'as', '1684'],
  ['Andorra', 'ad', '376'],
  ['Angola', 'ao', '244'],
  ['Anguilla', 'ai', '1264'],
  ['Antigua and Barbuda', 'ag', '1268'],
  ['Argentina', 'ar', '54'],
  ['Armenia (Հայաստան)', 'am', '374'],
  ['Aruba', 'aw', '297'],
  ['Australia', 'au', '61'],
  ['Austria (Österreich)', 'at', '43'],
  ['Azerbaijan (Azərbaycan)', 'az', '994'],
  ['Bahamas', 'bs', '1242'],
  ['Bahrain (‫البحرين‬‎)', 'bh', '973'],
  ['Bangladesh (বাংলাদেশ)', 'bd', '880'],
  ['Barbados', 'bb', '1246'],
  ['Belarus (Беларусь)', 'by', '375'],
  ['Belgium (België)', 'be', '32'],
  ['Belize', 'bz', '501'],
  ['Benin (Bénin)', 'bj', '229'],
  ['Bermuda', 'bm', '1441'],
  ['Bhutan (འབྲུག)', 'bt', '975'],
  ['Bolivia', 'bo', '591'],
  ['Bosnia and Herzegovina (Босна и Херцеговина)', 'ba', '387'],
  ['Botswana', 'bw', '267'],
  ['Brazil (Brasil)', 'br', '55'],
  ['British Indian Ocean Territory', 'io', '246'],
  ['British Virgin Islands', 'vg', '1284'],
  ['Brunei', 'bn', '673'],
  ['Bulgaria (България)', 'bg', '359'],
  ['Burkina Faso', 'bf', '226'],
  ['Burundi (Uburundi)', 'bi', '257'],
  ['Cambodia (កម្ពុជា)', 'kh', '855'],
  ['Cameroon (Cameroun)', 'cm', '237'],
  ['Canada', 'ca', '1'],
  ['Cape Verde (Kabu Verdi)', 'cv', '238'],
  ['Caribbean Netherlands', 'bq', '599'],
  ['Cayman Islands', 'ky', '1345'],
  ['Central African Republic (République centrafricaine)', 'cf', '236'],
  ['Chad (Tchad)', 'td', '235'],
  ['Chile', 'cl', '56'],
  ['China (中国)', 'cn', '86'],
  ['Christmas Island', 'cx', '61', 2],
  ['Cocos (Keeling) Islands', 'cc', '61', 1],
  ['Colombia', 'co', '57'],
  ['Comoros (‫جزر القمر‬‎)', 'km', '269'],
  ['Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)', 'cd', '243'],
  ['Congo (Republic) (Congo-Brazzaville)', 'cg', '242'],
  ['Cook Islands', 'ck', '682'],
  ['Costa Rica', 'cr', '506'],
  ['Côte d’Ivoire', 'ci', '225'],
  ['Croatia (Hrvatska)', 'hr', '385'],
  ['Cuba', 'cu', '53'],
  ['Curaçao', 'cw', '599', 0],
  ['Cyprus (Κύπρος)', 'cy', '357'],
  ['Czech Republic (Česká republika)', 'cz', '420'],
  ['Denmark (Danmark)', 'dk', '45'],
  ['Djibouti', 'dj', '253'],
  ['Dominica', 'dm', '1767'],
  ['Dominican Republic (República Dominicana)', 'do', '1', 2, ['809', '829', '849']],
  ['Ecuador', 'ec', '593'],
  ['Egypt (‫مصر‬‎)', 'eg', '20'],
  ['El Salvador', 'sv', '503'],
  ['Equatorial Guinea (Guinea Ecuatorial)', 'gq', '240'],
  ['Eritrea', 'er', '291'],
  ['Estonia (Eesti)', 'ee', '372'],
  ['Ethiopia', 'et', '251'],
  ['Falkland Islands (Islas Malvinas)', 'fk', '500'],
  ['Faroe Islands (Føroyar)', 'fo', '298'],
  ['Fiji', 'fj', '679'],
  ['Finland (Suomi)', 'fi', '358', 0],
  ['France', 'fr', '33'],
  ['French Guiana (Guyane française)', 'gf', '594'],
  ['French Polynesia (Polynésie française)', 'pf', '689'],
  ['Gabon', 'ga', '241'],
  ['Gambia', 'gm', '220'],
  ['Georgia (საქართველო)', 'ge', '995'],
  ['Germany (Deutschland)', 'de', '49'],
  ['Ghana (Gaana)', 'gh', '233'],
  ['Gibraltar', 'gi', '350'],
  ['Greece (Ελλάδα)', 'gr', '30'],
  ['Greenland (Kalaallit Nunaat)', 'gl', '299'],
  ['Grenada', 'gd', '1473'],
  ['Guadeloupe', 'gp', '590', 0],
  ['Guam', 'gu', '1671'],
  ['Guatemala', 'gt', '502'],
  ['Guernsey', 'gg', '44', 1],
  ['Guinea (Guinée)', 'gn', '224'],
  ['Guinea-Bissau (Guiné Bissau)', 'gw', '245'],
  ['Guyana', 'gy', '592'],
  ['Haiti', 'ht', '509'],
  ['Honduras', 'hn', '504'],
  ['Hong Kong (香港)', 'hk', '852'],
  ['Hungary (Magyarország)', 'hu', '36'],
  ['Iceland (Ísland)', 'is', '354'],
  ['India (भारत)', 'in', '91'],
  ['Indonesia', 'id', '62'],
  ['Iran (‫ایران‬‎)', 'ir', '98'],
  ['Iraq (‫العراق‬‎)', 'iq', '964'],
  ['Ireland', 'ie', '353'],
  ['Isle of Man', 'im', '44', 2],
  ['Israel (‫ישראל‬‎)', 'il', '972'],
  ['Italy (Italia)', 'it', '39', 0],
  ['Jamaica', 'jm', '1876'],
  ['Japan (日本)', 'jp', '81'],
  ['Jersey', 'je', '44', 3],
  ['Jordan (‫الأردن‬‎)', 'jo', '962'],
  ['Kazakhstan (Казахстан)', 'kz', '7', 1],
  ['Kenya', 'ke', '254'],
  ['Kiribati', 'ki', '686'],
  ['Kosovo', 'xk', '383'],
  ['Kuwait (‫الكويت‬‎)', 'kw', '965'],
  ['Kyrgyzstan (Кыргызстан)', 'kg', '996'],
  ['Laos (ລາວ)', 'la', '856'],
  ['Latvia (Latvija)', 'lv', '371'],
  ['Lebanon (‫لبنان‬‎)', 'lb', '961'],
  ['Lesotho', 'ls', '266'],
  ['Liberia', 'lr', '231'],
  ['Libya (‫ليبيا‬‎)', 'ly', '218'],
  ['Liechtenstein', 'li', '423'],
  ['Lithuania (Lietuva)', 'lt', '370'],
  ['Luxembourg', 'lu', '352'],
  ['Macau (澳門)', 'mo', '853'],
  ['Macedonia (FYROM) (Македонија)', 'mk', '389'],
  ['Madagascar (Madagasikara)', 'mg', '261'],
  ['Malawi', 'mw', '265'],
  ['Malaysia', 'my', '60'],
  ['Maldives', 'mv', '960'],
  ['Mali', 'ml', '223'],
  ['Malta', 'mt', '356'],
  ['Marshall Islands', 'mh', '692'],
  ['Martinique', 'mq', '596'],
  ['Mauritania (‫موريتانيا‬‎)', 'mr', '222'],
  ['Mauritius (Moris)', 'mu', '230'],
  ['Mayotte', 'yt', '262', 1],
  ['Mexico (México)', 'mx', '52'],
  ['Micronesia', 'fm', '691'],
  ['Moldova (Republica Moldova)', 'md', '373'],
  ['Monaco', 'mc', '377'],
  ['Mongolia (Монгол)', 'mn', '976'],
  ['Montenegro (Crna Gora)', 'me', '382'],
  ['Montserrat', 'ms', '1664'],
  ['Morocco (‫المغرب‬‎)', 'ma', '212', 0],
  ['Mozambique (Moçambique)', 'mz', '258'],
  ['Myanmar (Burma) (မြန်မာ)', 'mm', '95'],
  ['Namibia (Namibië)', 'na', '264'],
  ['Nauru', 'nr', '674'],
  ['Nepal (नेपाल)', 'np', '977'],
  ['Netherlands (Nederland)', 'nl', '31'],
  ['New Caledonia (Nouvelle-Calédonie)', 'nc', '687'],
  ['New Zealand', 'nz', '64'],
  ['Nicaragua', 'ni', '505'],
  ['Niger (Nijar)', 'ne', '227'],
  ['Nigeria', 'ng', '234'],
  ['Niue', 'nu', '683'],
  ['Norfolk Island', 'nf', '672'],
  ['North Korea (조선 민주주의 인민 공화국)', 'kp', '850'],
  ['Northern Mariana Islands', 'mp', '1670'],
  ['Norway (Norge)', 'no', '47', 0],
  ['Oman (‫عُمان‬‎)', 'om', '968'],
  ['Pakistan (‫پاکستان‬‎)', 'pk', '92'],
  ['Palau', 'pw', '680'],
  ['Palestine (‫فلسطين‬‎)', 'ps', '970'],
  ['Panama (Panamá)', 'pa', '507'],
  ['Papua New Guinea', 'pg', '675'],
  ['Paraguay', 'py', '595'],
  ['Peru (Perú)', 'pe', '51'],
  ['Philippines', 'ph', '63'],
  ['Poland (Polska)', 'pl', '48'],
  ['Portugal', 'pt', '351'],
  ['Puerto Rico', 'pr', '1', 3, ['787', '939']],
  ['Qatar (‫قطر‬‎)', 'qa', '974'],
  ['Réunion (La Réunion)', 're', '262', 0],
  ['Romania (România)', 'ro', '40'],
  ['Russia (Россия)', 'ru', '7', 0],
  ['Rwanda', 'rw', '250'],
  ['Saint Barthélemy (Saint-Barthélemy)', 'bl', '590', 1],
  ['Saint Helena', 'sh', '290'],
  ['Saint Kitts and Nevis', 'kn', '1869'],
  ['Saint Lucia', 'lc', '1758'],
  ['Saint Martin (Saint-Martin (partie française))', 'mf', '590', 2],
  ['Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)', 'pm', '508'],
  ['Saint Vincent and the Grenadines', 'vc', '1784'],
  ['Samoa', 'ws', '685'],
  ['San Marino', 'sm', '378'],
  ['São Tomé and Príncipe (São Tomé e Príncipe)', 'st', '239'],
  ['Saudi Arabia (‫المملكة العربية السعودية‬‎)', 'sa', '966'],
  ['Senegal (Sénégal)', 'sn', '221'],
  ['Serbia (Србија)', 'rs', '381'],
  ['Seychelles', 'sc', '248'],
  ['Sierra Leone', 'sl', '232'],
  ['Singapore', 'sg', '65'],
  ['Sint Maarten', 'sx', '1721'],
  ['Slovakia (Slovensko)', 'sk', '421'],
  ['Slovenia (Slovenija)', 'si', '386'],
  ['Solomon Islands', 'sb', '677'],
  ['Somalia (Soomaaliya)', 'so', '252'],
  ['South Africa', 'za', '27'],
  ['South Korea (대한민국)', 'kr', '82'],
  ['South Sudan (‫جنوب السودان‬‎)', 'ss', '211'],
  ['Spain (España)', 'es', '34'],
  ['Sri Lanka (ශ්‍රී ලංකාව)', 'lk', '94'],
  ['Sudan (‫السودان‬‎)', 'sd', '249'],
  ['Suriname', 'sr', '597'],
  ['Svalbard and Jan Mayen', 'sj', '47', 1],
  ['Swaziland', 'sz', '268'],
  ['Sweden (Sverige)', 'se', '46'],
  ['Switzerland (Schweiz)', 'ch', '41'],
  ['Syria (‫سوريا‬‎)', 'sy', '963'],
  ['Taiwan (台灣)', 'tw', '886'],
  ['Tajikistan', 'tj', '992'],
  ['Tanzania', 'tz', '255'],
  ['Thailand (ไทย)', 'th', '66'],
  ['Timor-Leste', 'tl', '670'],
  ['Togo', 'tg', '228'],
  ['Tokelau', 'tk', '690'],
  ['Tonga', 'to', '676'],
  ['Trinidad and Tobago', 'tt', '1868'],
  ['Tunisia (‫تونس‬‎)', 'tn', '216'],
  ['Turkey (Türkiye)', 'tr', '90'],
  ['Turkmenistan', 'tm', '993'],
  ['Turks and Caicos Islands', 'tc', '1649'],
  ['Tuvalu', 'tv', '688'],
  ['U.S. Virgin Islands', 'vi', '1340'],
  ['Uganda', 'ug', '256'],
  ['Ukraine (Україна)', 'ua', '380'],
  ['United Arab Emirates (‫الإمارات العربية المتحدة‬‎)', 'ae', '971'],
  ['Uruguay', 'uy', '598'],
  ['Uzbekistan (Oʻzbekiston)', 'uz', '998'],
  ['Vanuatu', 'vu', '678'],
  ['Vatican City (Città del Vaticano)', 'va', '39', 1],
  ['Venezuela', 've', '58'],
  ['Vietnam (Việt Nam)', 'vn', '84'],
  ['Wallis and Futuna', 'wf', '681'],
  ['Western Sahara (‫الصحراء الغربية‬‎)', 'eh', '212', 1],
  ['Yemen (‫اليمن‬‎)', 'ye', '967'],
  ['Zambia', 'zm', '260'],
  ['Zimbabwe', 'zw', '263'],
  ['Åland Islands', 'ax', '358', 1]
]

const allCountriesCode = allCountries
  .map((item) => {
    const code = item[1].toUpperCase()
    return {
      name: item[0],
      text: `${item[0]} (${code})`,
      value: code
    }
  })
  .sort((a, b) => a.text.localeCompare(b.text))

export const nameservers = ['ns1.mydnsservice.com', 'ns2.mydnsservice.com']

export {
  locale,
  email_support,
  packageStatusI18n,
  ipStatusI18n,
  chat_link,
  domainExtension,
  cancelReasonI18n,
  paidStatusI18n,
  paymentMethodI18n,
  transDiscount,
  square_appid,
  square_locationid,
  square_url,
  departmentsI18n,
  productTypeI18n,
  commissionStatusI18n,
  affOrderStatusI18n,
  colorMap,
  perUlrMap,
  shellURL,
  timezone,
  cloudclustersURL,
  portal_url,
  isDev,
  domain,
  status,
  packageCategory,
  packageStatus,
  deployStatus,
  cancellationTypes,
  app_key,
  server_name1,
  server_name2,
  limitSize,
  allCountries,
  runningStatusI18n,
  addonStatusI18n,
  allCountriesCode,
  categoryMap,
  purposeMap,
  approvalMethod,
  packageStatusFilter,
  packageStatusDefaultSelected
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { class: [_vm.wrapperClass, _vm.customClass] }, [
        _c(
          "div",
          { staticClass: "spin-center-container" },
          [
            _vm._t("default", [
              _c("v-progress-circular", {
                attrs: { size: 35, color: "blue lighten-3", indeterminate: "" },
              }),
              _vm.text
                ? _c("div", {
                    staticClass: "loading-text mt-4",
                    domProps: { innerHTML: _vm._s(_vm.text) },
                  })
                : _vm._e(),
            ]),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
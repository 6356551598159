<template>
  <Spin :show="true" class="empty-card">
    <slot>
      <span>{{ $t('no_data') }}</span>
    </slot>
  </Spin>
</template>

<script>
import Spin from './Spin.vue'

export default {
  name: 'Empty',
  components: { Spin },
  i18n: {
    sharedMessages: {
      en: {
        no_data: 'No data available'
      },
      zh_CN: {
        no_data: '没有可用数据'
      }
    }
  }
}
</script>

<style lang="scss">
.empty-card {
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "elevation-2 app-drawer",
      attrs: {
        "mini-variant-width": 50,
        "mobile-breakpoint": "991",
        "mini-variant": _vm.miniDrawer,
        width: _vm.miniDrawer ? 50 : 270,
        app: "",
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function () {
            return [
              _c("v-divider"),
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  staticStyle: {
                    "justify-content": "center",
                    "align-items": "center",
                    height: "40px",
                  },
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.miniHandler } },
                    [
                      _c("v-icon", [
                        _vm._v(
                          _vm._s(
                            _vm.miniDrawer ? "chevron_right" : "chevron_left"
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.$store.state.drawer,
        callback: function ($$v) {
          _vm.$set(_vm.$store.state, "drawer", $$v)
        },
        expression: "$store.state.drawer",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-center justify-center pa-1 mt-2",
          staticStyle: { height: "60px" },
        },
        [
          _c(
            "a",
            {
              staticStyle: { color: "#fff", "text-decoration": "none" },
              attrs: {
                href: _vm.logoHref,
                title: _vm.$t("logo_title"),
                target: "_blank",
              },
            },
            [
              _c("img", {
                staticClass: "img-logo",
                attrs: {
                  src: _vm.miniDrawer ? _vm.logoSrc[1] : _vm.logoSrc[0],
                },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex align-center justify-center pa-2",
          staticStyle: { height: "60px" },
        },
        [
          _vm.miniDrawer
            ? [
                _c(
                  "v-btn",
                  {
                    staticStyle: { "min-width": "30px", padding: "0 8px" },
                    attrs: {
                      depressed: "",
                      small: "",
                      tile: "",
                      title: _vm.$t("btn_add[1]"),
                      to: "/ordering",
                      color: "primary text-none",
                    },
                  },
                  [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("add")])],
                  1
                ),
              ]
            : _c(
                "tempalte",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        title: _vm.$t("btn_add[1]"),
                        to: "/ordering",
                        color: "primary text-none",
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("add")]),
                      _c("span", [_vm._v(_vm._s(_vm.$t("btn_add[0]")))]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        2
      ),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _vm._l(_vm.navMenus, function (menu, i) {
            return [
              menu.menus
                ? _c(
                    "v-list-group",
                    _vm._b(
                      {
                        key: menu.text,
                        attrs: { "no-action": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prependIcon",
                              fn: function () {
                                return [
                                  _c("d-icon", { staticClass: "fs-1" }, [
                                    _vm._v(_vm._s(menu.icon)),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "activator",
                              fn: function () {
                                return [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.$t(menu.text))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      "v-list-group",
                      menu.bindAttrs,
                      false
                    ),
                    [
                      _vm._l(menu.menus, function (item, i) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: i,
                              attrs: {
                                to: item.path,
                                "active-class": "nav-item-active",
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t(item.text))),
                              ]),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _c(
                    "v-list-item",
                    _vm._b(
                      {
                        key: i,
                        attrs: {
                          title: _vm.$t(menu.title),
                          "active-class": "nav-item-active",
                        },
                      },
                      "v-list-item",
                      menu.bindAttrs,
                      false
                    ),
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("d-icon", { staticClass: "fs-1" }, [
                            _vm._v(_vm._s(menu.icon)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t(menu.text))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import axios from 'axios'
import cookie from '@/module/utils/cookie'
import { login } from '@/module/service/oAuth'
import Vue from 'vue'
import i18n from '../../i18n'
// import router from '../../router'
let $http = axios.create({
  baseURL: '/v1',
  timeout: 120000
})

$http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = cookie.get('token')
    if (token) {
      config.headers['Authorization'] = `Token ${token}`
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
// Add a response interceptor
$http.interceptors.response.use(
  function (response) {
    // Do something with response data
    let data = response.data
    let code = data.code
    if (response.config.responseType === 'blob') {
      return response
    }
    if (code === 0) {
      return response.data.data
    } else {
      let detail = response.data.detail
      if (typeof detail !== 'string') {
        response.data.detail = i18n.tc('message.error_common[0]') // 'Request failed '
      }
      return Promise.reject(response)
    }
  },
  function (error) {
    let response = error.response
    let code = response.status
    let detail = error.response.data.detail
    if (typeof detail !== 'string') {
      error.response.data.detail = (detail && detail[0]) || i18n.tc('message.error_common[0]') // 'Request failed '
    }
    if (code === 401) {
      login()
      return Promise.resolve()
    }
    if (code === 403) {
      Vue.prototype.$message.error(error.response.data.detail)
      return Promise.reject()
    }

    if (error.response.data.code === 115528) {
      sessionStorage.setItem('isRedirectBlocked', true)
      window.location.reload()
    }

    // Do something with response error
    return Promise.reject(error.response.data)
  }
)

export default $http
